import { ChevronLeftIcon, ChevronRightIcon } from './Icons'
import { useRouter } from 'next/router'
import styled from 'styled-components'

interface PaginationButtonProps {
  $active?: boolean
}

const PaginationButton = styled.button<PaginationButtonProps>`
  border: none;
  height: 20px;
  font-size: 1rem;
  line-height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: ${({ theme, $active }) =>
    $active
      ? theme.colors.pagination.activeBackgroundColor
      : theme.colors.pagination.backgroundColor};

  &:hover {
    font-weight: bold;
    background-color: ${({ theme }) =>
      theme.colors.pagination.hoverBackgroundColor};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.buttonDisabled.color};
    background-color: ${({ theme }) =>
      theme.colors.pagination.disabledBackgroundColor};
  }
`

const PaginationContainerButton = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;
`

const PaginationContainer = styled.div`
  display: flex;
`

interface PaginationProps {
  totalCount: number
  pageSize: number
  // Option to prevent pagination events from scrolling to the top of the
  // screen. Useful if user interacts with mid-screen pagination.
  preventScroll?: boolean
}

export function Pagination({
  totalCount,
  pageSize,
  preventScroll,
}: PaginationProps): JSX.Element {
  const router = useRouter()
  const { query } = router

  const totalPages = Math.ceil(totalCount / pageSize)
  const pages = Array.from(new Array(totalPages), (_v, k) => k + 1)
  const offsetAsNumber = query.offset ? Number(query.offset) : 0

  const forwardPage = () => {
    if (offsetAsNumber < totalCount) {
      const nextOffset = offsetAsNumber + pageSize
      router.replace(
        {
          query: {
            ...router.query,
            offset: nextOffset.toString(),
          },
        },
        undefined,
        {
          scroll: !preventScroll,
        }
      )
    }
  }

  const backPage = () => {
    if (totalCount >= offsetAsNumber && offsetAsNumber >= pageSize) {
      const nextOffset = offsetAsNumber - pageSize
      router.replace(
        {
          query: {
            ...router.query,
            offset: nextOffset.toString(),
          },
        },
        undefined,
        {
          scroll: !preventScroll,
        }
      )
    }
  }

  return (
    <PaginationContainer>
      <PaginationContainerButton>
        <PaginationButton onClick={backPage} disabled={offsetAsNumber === 0}>
          <ChevronLeftIcon />
        </PaginationButton>
        {pages?.map((page) => (
          <PaginationButton
            key={page}
            $active={offsetAsNumber / pageSize === page - 1}
            onClick={() => {
              router.replace(
                {
                  query: {
                    ...router.query,
                    offset: ((page - 1) * pageSize).toString(),
                  },
                },
                undefined,
                {
                  scroll: !preventScroll,
                }
              )
            }}
          >
            {page}
          </PaginationButton>
        ))}
        <PaginationButton
          onClick={forwardPage}
          disabled={offsetAsNumber + pageSize >= totalCount}
        >
          <ChevronRightIcon />
        </PaginationButton>
      </PaginationContainerButton>
    </PaginationContainer>
  )
}
