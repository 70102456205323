import { useRouter } from 'next/router'
import { useEffect } from 'react'

const PAGINATION_QUERY_PARAMS = ['first', 'offset', 'orderBy']

export function useQueryParamVariables<T>(
  availableFilters: string[],
  variables: Record<string, unknown>,
  // TODO: <T> Not actually type safe, data is coerced dynamically
  setVariables: (variables: T) => void
) {
  // this function skips over "first, offset, teamId, orderBy"
  const { query } = useRouter()

  useEffect(() => {
    // if query param is an available filter add in query params add it to list variables
    // if query param is an available filter but not in query params remove it from list variables
    const placeholderVariables = { ...variables }

    const currentVariableKeys = Object.keys(placeholderVariables).filter(
      (key) =>
        availableFilters.includes(key) || PAGINATION_QUERY_PARAMS.includes(key)
    )
    const currentQueryParamKeys = Object.keys(query).filter(
      (key) =>
        availableFilters.includes(key) || PAGINATION_QUERY_PARAMS.includes(key)
    )
    const keysToRemove = currentVariableKeys.filter(
      // Do not remove pagination parameters as we always want to limit the result size
      (key) =>
        !currentQueryParamKeys.includes(key) &&
        !PAGINATION_QUERY_PARAMS.includes(key)
    )

    // adding a new key from variables based on query params
    if (currentQueryParamKeys.length > 0) {
      for (const currentQueryParamKey of currentQueryParamKeys) {
        const queryParamValue = query[currentQueryParamKey]?.toString() || ''
        if (queryParamValue.toLowerCase() === 'true') {
          placeholderVariables[currentQueryParamKey] = true
        } else if (queryParamValue.toLowerCase() === 'false') {
          placeholderVariables[currentQueryParamKey] = false
        } else if (/^[0-9]+$/.test(queryParamValue)) {
          placeholderVariables[currentQueryParamKey] = Number(queryParamValue)
        } else if (queryParamValue !== '') {
          placeholderVariables[currentQueryParamKey] = queryParamValue
        }
      }
    }
    if (keysToRemove.length > 0) {
      for (const keyToRemove of keysToRemove) {
        delete placeholderVariables[keyToRemove]
      }
    }
    setVariables(placeholderVariables as unknown as T)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query), JSON.stringify(variables)])
}
