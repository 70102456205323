import { useRouter } from 'next/router'
import { createContext } from 'react'
import { TeamType, useTeamQuery, useTeamsQuery, WhoamiQuery } from 'sdk'

interface UserContextInterface {
  currentUser: NonNullable<WhoamiQuery['whoami']>
  activeTeam?: TeamType
  userIsActiveTeamAdmin: boolean
  allUserTeams: TeamType[]
}

export const UserContext = createContext<UserContextInterface>({
  currentUser: {
    id: '',
    pk: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    isSuperuser: false,
  },
  activeTeam: undefined,
  userIsActiveTeamAdmin: false,
  allUserTeams: [],
})

interface TeamProviderInterface {
  currentUser: NonNullable<WhoamiQuery['whoami']>
  children: JSX.Element
}

export const TeamProvider = ({
  currentUser,
  children,
}: TeamProviderInterface) => {
  const router = useRouter()
  const { teamSlug } = router?.query || {}

  const { data: teamsData, isLoading: teamsLoading } = useTeamsQuery()

  // Get all teams the user has access too
  const allUserTeams: TeamType[] =
    teamsData?.teams?.edges
      ?.map((edge) => {
        if (edge?.node && edge.node !== null && edge.node !== undefined) {
          return edge.node
        }
      })
      .filter((t): t is TeamType => Boolean(t)) || []

  // determine the active team based on the URL params
  const activeTeamFromParams = allUserTeams.find(
    (team) => team && team.slug === teamSlug
  )

  // now fetch the active team's entire data
  // this is required to determine the user's permissions against this team
  const resolveTeam = activeTeamFromParams?.id !== undefined
  const { data: teamData, isLoading: teamLoading } = useTeamQuery(
    { id: activeTeamFromParams?.id || '' }, // activeTeam.id will not be empty here, but required by TypeScript
    { enabled: resolveTeam }
  )

  // Only render once the user has been resolved to simplify sub-component
  // implementation and reduce redundant network calls
  const isPendingUserContext = teamsLoading || (resolveTeam && teamLoading)

  if (isPendingUserContext) {
    return <div>Loading user data...</div>
  }

  const adminGroupUsers =
    teamData?.team?.adminGroup?.userSet?.edges?.map((edge) => {
      if (edge?.node && edge.node !== null && edge.node !== undefined) {
        return edge.node
      }
    }) || []

  const userIsActiveTeamAdmin = adminGroupUsers.some(
    (adminUsers) => adminUsers?.id === currentUser.id
  )

  return (
    <UserContext.Provider
      value={{
        currentUser,
        activeTeam: activeTeamFromParams,
        userIsActiveTeamAdmin,
        allUserTeams,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
