import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useUrls } from 'utils'
interface FetchParamContextInterface {
  connectedSuccessfully?: boolean | undefined
  setConnectedSuccessfully?: Dispatch<SetStateAction<boolean | undefined>>
}

export const FetchParamsContext = createContext<FetchParamContextInterface>({
  connectedSuccessfully: undefined,
  setConnectedSuccessfully: () => {
    return
  },
})

interface FetchParamsProviderInterface extends FetchParamContextInterface {
  children: JSX.Element
}

export const FetchParamsProvider = ({
  children,
}: FetchParamsProviderInterface) => {
  const [connectedSuccessfully, setConnectedSuccessfully] = useState<
    boolean | undefined
  >(undefined)

  return (
    <FetchParamsContext.Provider
      value={{
        connectedSuccessfully,
        setConnectedSuccessfully,
      }}
    >
      {children}
    </FetchParamsContext.Provider>
  )
}

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { setConnectedSuccessfully } = useContext(FetchParamsContext)
  const { GRAPHQL_API_URL } = useUrls()

  return async (variables?: TVariables) => {
    const response = await fetch(`${GRAPHQL_API_URL}/graphql/`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(options ?? {}),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    }).catch((error) => {
      console.error('Tenstorrent Fetch Error: ', error)
      setConnectedSuccessfully?.(false)
      throw new Error(error)
    })

    return await response?.json().then((json) => {
      if (json.errors) {
        const { message } = json.errors[0] || 'Error..'
        throw new Error(message)
      }

      return json.data
    })
  }
}
