import styled from 'styled-components'
import { ButtonHTMLAttributes } from 'react'
import { CheckIcon, ExclamationCircleIcon, PendingIcon } from './Icons'
export const Button = styled.button`
  font-size: 1rem;
  line-height: 32px;
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: ${({ theme }) => theme.colors.button.color};
  background-color: ${({ theme }) => theme.colors.button.background};

  border-color: ${({ theme }) => theme.colors.button.border};
  border-radius: var(--border-radius);
  border-style: solid;
  border-width: 1px;

  &:hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.button.hoverColor};
    background-color: ${({ theme }) => theme.colors.button.hoverBackground};
    border-color: ${({ theme }) => theme.colors.button.hoverBorder};
  }

  &[type='reset'] {
    color: ${({ theme }) => theme.colors.buttonSecondary.color};
    background-color: ${({ theme }) => theme.colors.buttonSecondary.background};
    border-color: ${({ theme }) => theme.colors.buttonSecondary.border};

    &:hover {
      color: ${({ theme }) => theme.colors.buttonSecondary.hoverColor};
      background-color: ${({ theme }) =>
        theme.colors.buttonSecondary.hoverBackground};
      border-color: ${({ theme }) => theme.colors.buttonSecondary.hoverBorder};
    }
  }

  &[type='submit'] {
    color: ${({ theme }) => theme.colors.buttonSubmit.color};
    background-color: ${({ theme }) => theme.colors.buttonSubmit.background};
    border-color: ${({ theme }) => theme.colors.buttonSubmit.border};
    border-style: solid;
    border-width: 1px;

    &:hover {
      color: ${({ theme }) => theme.colors.buttonSubmit.hoverColor};
      background-color: ${({ theme }) =>
        theme.colors.buttonSubmit.hoverBackground};
      border-color: ${({ theme }) => theme.colors.buttonSubmit.hoverBorder};
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.colors.buttonSubmit.activeBackground};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.buttonDisabled.color};
    background-color: ${({ theme }) => theme.colors.buttonDisabled.background};
    border-color: ${({ theme }) => theme.colors.buttonDisabled.border};
    cursor: default;

    &:hover {
      color: ${({ theme }) => theme.colors.buttonDisabled.hoverColor};
      background-color: ${({ theme }) =>
        theme.colors.buttonDisabled.hoverBackground};
      border-color: ${({ theme }) => theme.colors.buttonDisabled.hoverBorder};
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.colors.buttonDisabled.activeBackground};
    }
  }
`

const ButtonDanger = styled(Button)`
  &[type='submit'],
  &[type='button'] {
    color: ${({ theme }) => theme.colors.buttonSubmitDanger.color};
    background-color: ${({ theme }) =>
      theme.colors.buttonSubmitDanger.background};
    border-color: ${({ theme }) => theme.colors.buttonSubmitDanger.border};
    border-style: solid;
    border-width: 1px;

    &:hover {
      color: ${({ theme }) => theme.colors.buttonSubmitDanger.hoverColor};
      background-color: ${({ theme }) =>
        theme.colors.buttonSubmitDanger.hoverBackground};
      border-color: ${({ theme }) =>
        theme.colors.buttonSubmitDanger.hoverBorder};
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.colors.buttonSubmitDanger.activeBackground};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.buttonDisabled.color};
      background-color: ${({ theme }) =>
        theme.colors.buttonDisabled.background};
      border-color: ${({ theme }) => theme.colors.buttonDisabled.border};
      cursor: default;
    }
  }
`

type ButtonAsyncProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  idleIcon?: JSX.Element
  loading: boolean
  danger?: boolean
}

const Wrapper = styled.span`
  margin-left: 5px;
  display: inline-flex;
`

// Button with additional idle and loading icons used to indicate state of
// async submissions to the user.
// Button is disabled if "loading: true"
export const ButtonAsync = ({
  idleIcon,
  loading,
  danger,
  children,
  ...props
}: ButtonAsyncProps): JSX.Element => {
  const icon = loading ? (
    <PendingIcon style={{ color: 'inherit' }} />
  ) : idleIcon ? (
    idleIcon
  ) : danger ? (
    <ExclamationCircleIcon style={{ color: 'inherit' }} />
  ) : (
    <CheckIcon />
  )
  const ButtonComponent = danger ? ButtonDanger : Button

  const { disabled, ...propsWithoutDisabled } = props

  return (
    <ButtonComponent
      disabled={loading ? true : disabled}
      {...propsWithoutDisabled}
    >
      {children}
      <Wrapper>{icon}</Wrapper>
    </ButtonComponent>
  )
}
