import { useRouter } from 'next/router'
import { FormEventHandler, useState } from 'react'
import { LoginInput, useLoginMutation } from 'sdk'
import styled from 'styled-components'
import { useUrls } from 'utils'
import {
  ButtonAsync,
  Form,
  FormError,
  FormTextInput,
  Heading,
  LoginIcon,
} from '.'

const LoginFormStyles = styled(Form)`
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  text-wrap: wrap;

  grid-area: main;
  display: flex;
  margin: auto;
`

interface ERROR {
  message: string
}

export function LoginForm(): JSX.Element {
  const router = useRouter()
  const { CLOUD_URL } = useUrls()
  const { mutate, isLoading, isError, error } = useLoginMutation<ERROR>()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    const input: LoginInput = {
      username,
      password,
    }
    await mutate(
      {
        input,
      },
      {
        onSuccess: () => {
          const { redirect } = router?.query || {}
          if (redirect) {
            router.push(redirect.toString())
          } else {
            router.push(CLOUD_URL)
          }
        },
      }
    )
  }

  const isReady = username.length > 0 && password.length > 0

  return (
    <LoginFormStyles onSubmit={onSubmit}>
      <Heading>Log in to Tenstorrent</Heading>
      <FormTextInput
        label={'Username'}
        type="username"
        id="username"
        name="username"
        onChange={(event) => setUsername(event.currentTarget.value)}
        autoFocus
        required
      />
      <FormTextInput
        label={'Password'}
        type="password"
        id="password"
        name="password"
        onChange={(event) => setPassword(event.currentTarget.value)}
        required
      />
      <ButtonAsync
        disabled={!isReady}
        loading={isLoading}
        idleIcon={<LoginIcon />}
        type="submit"
      >
        Log In
      </ButtonAsync>
      {isError && <FormError>{error?.message?.toString()}</FormError>}
    </LoginFormStyles>
  )
}
